import { useState } from 'react';
import { Link } from '@skatteetaten/frontend-components/Link';
import { TopBanner } from '@skatteetaten/frontend-components/TopBanner';
import { TopStripe } from '@skatteetaten/frontend-components/TopStripe';
import { LanguagePicker } from '@skatteetaten/frontend-components/TopStripe/LanguagePicker';
import { TopStripeMenu } from '@skatteetaten/frontend-components/TopStripe/TopStripeMenu';
import { SkeBasis } from '@skatteetaten/frontend-components/SkeBasis';
import { TopStripeButton } from '@skatteetaten/frontend-components/TopStripe/TopStripeButton';
import menu from '../translations/menu_no.json';

import './Header.css'

/* eslint-disable jsx-a11y/anchor-is-valid */

const Header = (props) => {

    const [language, setLanguage] = useState('nb');

    return (
        <SkeBasis>
            <TopStripe className={'col-md-5 p-lg-2 mx-auto'} >
                <Link path={'#main-content-id'} text={'Hopp til hovedinnhold'} skipLink />
                <Link
                    path={'https://www.google.com'}
                    text={'Kontakt oss'}
                    placement="before"
                />
                <TopStripeMenu
                    closeMenuAriaLabel="Lukk endre skriftstørrelse"
                    title={'Endre skriftstørrelse'}
                    showOnMobile={false}
                    contentIsMenu={false}
                >
                    <div style={{ fontSize: '24px', marginTop: '8px' }}>
                        Hold Ctrl-tasten nede (Cmd-tasten på Mac). Trykk på + for å forstørre
                        eller - for å forminske.
                    </div>
                </TopStripeMenu>
                <LanguagePicker
                    selectedLanguage={language}
                    setLanguage={setLanguage}
                    showOnMobile={true}
                    showSami={true}
                />
                <Link path={'#link'} text={'Logg inn'} placement="before" />
            </TopStripe>
        </SkeBasis>
    );
}
export default Header;