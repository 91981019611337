import React, { useState } from 'react';
import { InputGroup, Checkbox, Input, TextArea } from '@sb1/ffe-form-react';
import { ActionButton } from '@sb1/ffe-buttons-react';
import { Heading3 } from '@sb1/ffe-core-react';

import Header from "../header/Header";
import Footer from "../footer/Footer";
import '../less/ffe.less';
import '../less/form.less';


const Work = () => {

    const [showErrors, setShowErrors] = useState(false);

    return (
        <div>
            <Header/>

            <div className="position-relative overflow-hidden p-3 theme-blue-background">
                <div className="col-md-5 p-lg-5 mx-auto">
                    <h1 className="display-4 fw-normal">Arbeid</h1>
                    <p className="lead fw-normal">And an even wittier subheading to boot. Jumpstart your marketing
                        efforts with this example based on Apple’s marketing pages.</p>
                </div>
            </div>

            <div className="position-relative overflow-hidden p-5">
                <div className="col-md-3 p-lg-2 mx-auto p-5">
                    <Heading3>Send opplysninger</Heading3>
                    <InputGroup
                        label="Navn"
                        fieldMessage={showErrors ? 'Ugyldig e-postadresse' : null}
                    >
                        <Input />
                    </InputGroup>
                    <InputGroup
                        label="E-postadresse"
                        fieldMessage={showErrors ? 'Ugyldig e-postadresse' : null}
                    >
                        <Input />
                    </InputGroup>

                    <InputGroup
                        label="Mobil"
                        fieldMessage={showErrors ? 'Ugyldig e-postadresse' : null}
                    >
                        <Input />
                    </InputGroup>

                    <InputGroup
                        label="Fritekst"
                        fieldMessage={showErrors ? 'Du må skrive noe lurt' : null}
                    >
                        <TextArea rows={6} />
                    </InputGroup>

                    <InputGroup fieldMessage={showErrors ? 'Ooops' : null}>
                        <Checkbox name="check">Kryssboks</Checkbox>
                    </InputGroup>

                    <ActionButton
                        isLoading={false}
                        onClick={f => f}
                        ariaLoadingMessage="Vennligst vent..."
                    >
                        Send inn
                    </ActionButton>
                </div>
            </div>

            <Footer/>
        </div>
    );
}
export default Work;