import React, { useState } from 'react';

import Header from "../header/Header";
import Footer from "../footer/Footer";

import '../less/ffe-accordion.less';
import { Accordion, AccordionItem } from '@sb1/ffe-accordion-react';
import { BulletList, BulletListItem } from '@sb1/ffe-lists-react';
import { LinkText } from '@sb1/ffe-core-react';

const Business = () => {

    const [isUserNavOpen, setIsUserNavOpen] = useState(false);

    return (
        <div>
            <Header active={'business'} />

            <div className="position-relative overflow-hidden p-3 theme-blue-background">
                <div className="col-md-5 p-lg-5 mx-auto">
                    <h1 className="display-4 fw-normal">Vi hjelper din bedrift</h1>
                    <p className="lead fw-normal">I følge Arbeidstilsynet, er det arbeidsgivers ansvar å utarbeide en skriftlig arbeidsavtale.
                        Mange konflikter kan unngås med en tydelig arbeidsavtale som er inngått så tidlig som mulig.
                        Vi kan hjelpe deg med både utarbeidelse og endringer i arbeidsavtalen.</p>
                </div>
            </div>

            <div className="container px-1 py-1">
                <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                    <Accordion headingLevel={3}>
                        <AccordionItem heading="Ansettelse">Hovedregelen er at arbeidstaker skal ansettes fast. Det skal alltid inngås skriftlig arbeidsavtale, uavhengig av arbeidsforholdets varighet og størrelse på stillingen. Hvis det avtales prøvetid må dette gjøres skriftlig, og prøvetiden skal ikke være lengre enn seks måneder. <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/ansettelse/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>
                        <AccordionItem heading="Arbeidsavtale">Arbeidstakere skal alltid ha en skriftlig arbeidsavtale. Dette gjelder uansett om det er en fast eller midlertidig stilling. Det er ingen unntak fra denne regelen. Det er arbeidsgivers ansvar å utarbeide en skriftlig arbeidsavtale. Mange konflikter kan unngås med en tydelig arbeidsavtale som er inngått så tidlig som mulig. Arbeidstakere har rett til å få hjelp fra tillitsvalgt eller annen representant både ved utarbeidelse og endringer i arbeidsavtalen. <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/arbeidsavtale/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>
                        <AccordionItem heading="Arbeidstid">Arbeidstid er den tiden arbeidstaker står til disposisjon for arbeidsgiver. Regelverket har grenser for arbeidstiden og krav om pauser og daglig og ukentlig fri. Arbeidstaker har rett til minst én pause når den daglige arbeidstiden overstiger fem og en halv time. Dersom den daglige arbeidstiden er åtte timer eller mer, skal pausene til sammen være minst en halv time. <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/arbeidstid/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>
                        <AccordionItem heading="Ferie">Alle arbeidstakere har krav på minst 25 virkedager ferie hvert år. Hverdager inkludert lørdager er virkedager. Søndager og helligdager regnes ikke som virkedager. Normalt vil seks virkedager tilsvare en uke. Arbeidstaker har altså krav på fire uker og en dag ferie hvert kalenderår. <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/ferie/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>
                        <AccordionItem heading="Generelle krav til opplæring">Arbeidsgiver skal sørge for at alle arbeidstakere får den opplæringen, øvelsen og instruksjonen de trenger for å utføre arbeidet på en sikker måte og for å kunne mestre arbeidsoppgavene sine. <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/generelle-krav-til-opplaring/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>
                        <AccordionItem heading="Kontroll og overvaking">Tilsette har rett til personvern og privatliv også på jobb. Men denne retten vil kunne bli innskrenka av det legitime behovet arbeidsgivaren har for forsvarleg drift. Arbeidsgivaren har også rett til å verne verksemda mot uønskte eller ulovlege handlingar. Dette inneber at arbeidsgivaren i nokre tilfelle har rett til å sette i verk visse kontrolltiltak. Den teknologiske utviklinga gjer det mogleg med stadig meir omfattande kontrollverksemd, derfor er det sett grenser for kva som er tillate. <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/kontroll-og-overvakning/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>
                        <AccordionItem heading="Krav til gjennomgående, dokumentert og sertifisert opplæring">Arbeidsgiver skal sørge for at ansatte får den opplæring, den praktiske øvelse og de instruksjoner som er nødvendig for å utføre arbeidet på en trygg måte. Legg bruksanvisningen til grunn. Riktig opplæring bidrar til reduksjon i ulykker, skader og sykdom. <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/opplaring/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>
                        <AccordionItem heading="Lønn">Det er ingen generell minstelønn i Norge. Lønn blir avtalt mellom arbeidsgiver og arbeidstaker som en del av den skriftlige arbeidsavtalen. Selv om det ikke er noen generell minstelønn i Norge, er det i enkelte bransjer innført minstelønn.<LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/lonn/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>
                        <AccordionItem heading="Medvirkning">Godt arbeidsmiljøarbeid avhenger av at arbeidstakerne og arbeidsgiverne samarbeider om å finne gode løsninger. Medvirkning innebærer at arbeidstakernes synspunkter og innspill skal høres og vurderes nøye, før arbeidsgiver tar en endelig beslutning. <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/medvirkning/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>

                        <AccordionItem heading="Omstilling">Informasjon, medverknad og fagleg utvikling må til for at ei omstilling skal bli konstruktiv og positiv for den enkelte arbeidstakaren.
                            <div>
                                <p></p>
                                <h6>Hva er omstilling?</h6>
                                <h7>Omstilling og endring i arbeidslivet er til dømes</h7>
                                <BulletList>
                                    <BulletListItem>eigarskifte eller leiarskifte</BulletListItem>
                                    <BulletListItem>oppsplitting eller samanslåing av verksemder og avdelingar</BulletListItem>
                                    <BulletListItem>omorganisering – endring i organisasjonskart og tilsette</BulletListItem>
                                    <BulletListItem>ny teknologi</BulletListItem>
                                    <BulletListItem>nye arbeidstidsordningar</BulletListItem>
                                    <BulletListItem>nye produkt eller tenester</BulletListItem>
                                </BulletList>
                                <p></p>
                            </div>
                            <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/omstilling/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>

                        <AccordionItem heading="Oppsigelse">Et arbeidsforhold avsluttes ved at enten arbeidsgiver eller arbeidstaker leverer en skriftlig oppsigelse. Det er faste regler for hvordan en oppsigelse skal skje - både for arbeidstaker og arbeidsgiver. Med unntak av tidsbestemte arbeidsforhold, som for eksempel vikariater, vil et arbeidsforhold ikke avsluttes av seg selv. Arbeidsmiljølovens regler om oppsigelse gjelder for de fleste virksomheter og arbeidstakere. <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/oppsigelse/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>
                        <AccordionItem heading="Permisjoner">Permisjon er en rett for arbeidstaker til å være borte fra arbeidet sitt i et begrenset tidsrom. Permisjon kan være lønnet – helt eller delvis – eller ulønnet. En permisjon er midlertidig. Dette betyr at arbeidstakeren fremdeles er ansatt og har rett og plikt til å komme tilbake til stillingen sin når permisjonen er ferdig. <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/permisjoner/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>
                        <AccordionItem heading="Permittering (Korona)">Permittering er en midlertidig ordning der arbeidstaker er pålagt arbeidsfritak og arbeidsgiver samtidig er fritatt lønnsplikten sin. Arbeidsforholdet består og det forutsettes at arbeidsstans kun er midlertidig. <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/permittering/">Referanse: Arbeidstilsynet</LinkText>
                            <div>
                                <p></p>
                                <h6>Skal du permittere på grunn av korona? Ta kontakt med oss.</h6>
                                <p></p>
                            </div>
                        </AccordionItem>
                        <AccordionItem heading="Tilrettelegging av arbeidet">Tilrettelegging er alle de tiltak man iverksetter i forbindelse med organisering og utførelse av arbeidet, utforming av arbeidsplassen og arbeidsmiljøet generelt. Arbeidsgiver skal organisere arbeidet og legge det til rette slik at arbeidstakerne kan være i jobb og være like friske når de går hjem fra arbeidet som da de kom. <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/tilrettelegging/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>
                        <AccordionItem heading="Tvisteløsningsnemnda">Etter arbeidsmiljøloven kan visse tvister avgjøres i tvisteløsningsnemnda. Tvisteløsningsnemnda er et eget forvaltningsorgan og er ikke underlagt Arbeidstilsynet, men Arbeidstilsynet fungerer som sekretariat for nemnda. <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/tvistelosningsnemnda/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>
                        <AccordionItem heading="Vern mot diskriminering">Forboda mot diskriminering i arbeidslivet er regulert i arbeidsmiljølova og likestillings- og diskrimineringslova.
                            <div>
                                <p></p>
                                <h6>Likstillings- og diskrimineringslova har forbod mot diskriminering som gjeld forskjellsbehandling på grunn av:</h6>
                                <BulletList>
                                    <BulletListItem>kjønn</BulletListItem>
                                    <BulletListItem>graviditet</BulletListItem>
                                    <BulletListItem>permisjon ved fødsel eller adopsjon</BulletListItem>
                                    <BulletListItem>omsorgsoppgåver</BulletListItem>
                                    <BulletListItem>etnisitet (mellom anna nasjonalt opphav, hudfarge og språk)</BulletListItem>
                                    <BulletListItem>religion</BulletListItem>
                                    <BulletListItem>livssyn</BulletListItem>
                                    <BulletListItem>nedsatt funksjonsevne</BulletListItem>
                                    <BulletListItem>seksuell orientering</BulletListItem>
                                    <BulletListItem>kjønnsidentitet</BulletListItem>
                                    <BulletListItem>kjønnsuttrykk</BulletListItem>
                                    <BulletListItem>alder</BulletListItem>
                                </BulletList>
                                <p></p>
                            </div>
                            <LinkText  target="_blank" href="https://www.arbeidstilsynet.no/arbeidsforhold/vern-mot-diskriminering/">Referanse: Arbeidstilsynet</LinkText></AccordionItem>
                    </Accordion>
                </div>
            </div>

            <Footer/>
        </div>
    );
}
export default Business;