import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.less";
import "./less/ffe.less";
import Home from "./home/Home";
import Work from "./work/Work";
import Business from "./business/Business";
import Car from "./car/Car";
import Policy from "./policy/Policy";

function App() {
  return (
    <div className="App">
      <div>
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/business" element={<Business />} />
                  <Route path="/bedrift" element={<Business />} />
                  <Route path="/work" element={<Work />} />
              </Routes>
          </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
