import React, { useState } from 'react';
import axios from 'axios';
import Header from "../header/Header";
import Footer from "../footer/Footer";

import './Home.css';
import '../less/cards.less';
import '../less/ffe-message-box.less';
import '../less/ffe-details-list-react.less';
import Datepicker from '@sb1/ffe-datepicker-react';
import Dropdown from '@sb1/ffe-dropdown-react';
import { SuccessMessage } from '@sb1/ffe-message-box-react';
import { Paragraph } from '@sb1/ffe-core-react';
import { DetailList, Detail, DetailContent } from '@sb1/ffe-details-list-react';
import {
    InputGroup,
    Checkbox,
    Input,
    TextArea,
    RadioButtonInputGroup,
    RadioBlock,
    RadioButton
} from '@sb1/ffe-form-react';
import { ActionButton, ButtonGroup } from '@sb1/ffe-buttons-react';

const Home = () => {

    const [name, setName] = useState(null);
    const [mobile, setMobile] = useState(null);
    const [email, setEmail] = useState(null);
    const [message, setMessage] = useState(null);
    const [selected, setSelected] = useState(null);
    const [showErrors, setShowErrors] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    function sendMessage(){

        if(invalidInputs()){
            setShowErrors(true);
            return false
        }

        axios.post('https://api.uforetrygd.no/contact', {
            name: name,
            email: email,
            mobile: mobile,
            message: message,
            source: 'via voldinærerelasjoner-site'
        })
            .then((response) => {
                return response.status === 200 ? setSubmitted(true) : false;
            }, (error) => {
                return false;
            });
    };

    function invalidInputs(){
        if(
            name == null || name.length === 0 ||
            mobile == null || mobile.length === 0 ||
            email == null || email.length === 0 ||
            message == null || message.length === 0 ||
            selected == null || selected !== 'Jeg ønsker å bli kontaktet for min sak.'
        ){
            return true
        } else {
            return false;
        }
    }

    function isNull(value){
        if(value == null){
            return true;
        } else if(value.length === 0){
            return true;
        } else {
            return false;
        }
    }

    return (
        <div>
            <div className="position-relative overflow-hidden p-3 theme-blue-background">
                <div className="col-md-5 p-lg-2 mx-auto">
                    <h1 className="display-4 fw-normal">ANMELDE VOLD?</h1>
                    <p className="lead fw-normal">Voldsutsatte befinner seg ofte i en situasjon og vet ikke hvor de skal søke hjelp. Vi hjelper deg med å finne advokathjelp for å ivareta dine interesser og rettigheter i saken mot voldsutøver.</p>
                </div>
            </div>

            <div className="container px-1 py-1">

                { submitted
                    ? // if
                    <div className="col-md-6 p-lg-6 mx-auto">
                        <SuccessMessage title="Sendt">
                            <Paragraph>En advokat vil kotankte deg snart.</Paragraph>
                        </SuccessMessage>
                    </div>

                    : // else
                    <div className="col-md-5 p-lg-5 mx-auto">
                        <InputGroup
                            label="Navn"
                            onChange={e => setName(e.target.value)}
                            fieldMessage={ (showErrors && isNull(name)) ? 'Navnet må fylles ut' : null}
                        ><Input />
                        </InputGroup>

                        <InputGroup
                            label="Mobil"
                            onChange={e => setMobile(e.target.value)}
                            fieldMessage={ (showErrors && isNull(mobile)) ? 'Mobilnummeret er ugyldig' : null}
                        ><Input />
                        </InputGroup>

                        <InputGroup
                            label="Epost"
                            onChange={e => setEmail(e.target.value)}
                            fieldMessage={ (showErrors && isNull(email)) ? 'Epost er ugyldig' : null}
                        ><Input />
                        </InputGroup>

                        <InputGroup
                            label="Sak"
                            onChange={e => setMessage(e.target.value)}
                            fieldMessage={ (showErrors && isNull(message)) ? 'Beskriv kort om saken' : null}
                        >
                            <TextArea rows={4} />
                        </InputGroup>

                        <RadioButtonInputGroup
                            name="owner"
                            onChange={e => setSelected(e.target.value)}
                            fieldMessage={ (showErrors && selected !== 'Jeg ønsker å bli kontaktet for min sak.') ? 'Vi trenger din samtykke for å kontakte deg.' : null}
                            selectedValue={selected}
                        >
                            {inputProps => (
                                <>
                                    <RadioBlock {...inputProps} label="Jeg ønsker å bli kontaktet for min sak." value="Jeg ønsker å bli kontaktet for min sak." />
                                </>
                            )}
                        </RadioButtonInputGroup>


                        <ButtonGroup thin={true}>
                            <ActionButton
                                isLoading={false}
                                onClick={() => sendMessage()}
                                ariaLoadingMessage="Vennligst vent..."
                            >
                                Send
                            </ActionButton>
                        </ButtonGroup>
                    </div>

                }

            </div>
        </div>
    );
}
export default Home;